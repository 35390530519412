// Title
$modal-title-margin: rem-calc(0 0 24);
$modal-title-margin--large: rem-calc(0 0 24);
$modal-title-font: normal #{rem-calc(24)} / 1.33 $font-secondary;
$modal-title-font--large: normal #{rem-calc(32)} / 1 $font-secondary;
$modal-title-text-transform: none;
$modal-title-text-transform--large: none;
$modal-title-text-align: center;
$modal-title-text-align--large: center;
$modal-wrapper-padding--large: rem-calc(40 62 62);
$modal-wrapper-center-padding--large: rem-calc(40 62);
$modal-close-icon-size: rem-calc(18);
$modal-close-icon-size--large: $modal-close-icon-size;
$modal-close-position--medium: rem-calc(20);
$modal-body-font: #{rem-calc(14)} / 1.5 $font-primary;// Header
$modal-header-font: $modal-title-font;
$modal-header-font--large: $modal-title-font--large;
$modal-header-text-transform: $modal-title-text-transform;
$modal-header-text-transform--large: $modal-title-text-transform--large;
$modal-header-text-align: $modal-title-text-align;
$modal-header-text-align--large: $modal-title-text-align--large;
// Subtitle
$modal-subtitle-font: #{rem-calc(14)} / 1.25 $font-tertiary;
$modal-subtitle-font--large: #{rem-calc(18)} / 1.25 $font-tertiary;

@import '@lora/05-components/modal';