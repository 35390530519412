$footer-social-list-justify-content: center;
$footer-social-list-justify-content--large: normal;
$footer-social-link-padding--large: rem-calc(10);

$footer-social-list: (
    messenger: (
        enabled: true,
        icon: footer-messenger,
        icon-height: rem-calc(25),
        icon-width: rem-calc(25)
    ),
    facebook: (
        enabled: true,
        icon: footer-facebook,
        icon-height: rem-calc(25),
        icon-width: rem-calc(25)
    ),
    twitter: (
        enabled: true,
        icon: twitter,
        icon-height: rem-calc(25),
        icon-width: rem-calc(25)
    ),
    instagram: (
        enabled: true,
        icon: footer-instagram,
        icon-height: rem-calc(25),
        icon-width: rem-calc(25)
    ),
    youtube: (
        enabled: true,
        icon: footer-youtube,
        icon-height: rem-calc(25),
        icon-width: rem-calc(25)
    ),
    pinterest: (
        enabled: true,
        icon: footer-pinterest,
        icon-height: rem-calc(25),
        icon-width: rem-calc(25)
    ),
    tiktok: (
        enabled: true,
        icon: footer-tiktok,
        icon-height: rem-calc(25),
        icon-width: rem-calc(25)
    ),
);

@import '@lora/05-components/footer/footer-social';