/// info promotion
/// @type Map
$promotion-info: (
    color: color(dark),
    icon: 'info',
    icon-color: color(dark)
);

// Message with icon
$promotion-icon-color: color(dark);

// 1st type of a promo message
$promotion-product-color: color(text);
$promotion-product-text-align: left;
$promotion-product-font: #{rem-calc(12)} / 1.4 $font-primary;
$promotion-product-tooltip-color: color(text);
$promotion-product-tooltip-font: bold #{rem-calc(14)} / 1 $font-tertiary;
$promotion-product-tooltip-flex-shrink: 0;
$promotion-product-tile-color: color(alert);
$promotion-product-border: none;
$promotion-product-border-radius: 0;
$promotion-product-background: color(active-background);

// 2nd type of a promo message
$promotion-approaching-discount-background: color(border);
$promotion-approaching-discount-font: bold #{rem-calc(12)} / 1.5 $font-tertiary;
$promotion-approaching-discount-padding: rem-calc(10);

@import '@lora/05-components/promotion';