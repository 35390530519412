$header-simple-search-overflow-x: visible;
$header-simple-search-overflow-y: visible;

$header-simple-search-secondary-padding: rem-calc(10 0);
$header-simple-search-secondary-position--large: relative;
$header-simple-search-secondary-field-height--large: rem-calc(24);
$header-simple-search-secondary-field-padding--large: rem-calc(0 0 0 10);
$header-simple-search-secondary-padding--large: rem-calc(16 0);
$header-simple-search-secondary-input-group-width--large: rem-calc(200);
$header-simple-search-secondary-field-background-color--large: color(light);
$header-simple-search-secondary-input-group-background--large: color(light);
$header-simple-search-secondary-field-padding: rem-calc(0 5);
$header-simple-search-secondary-results-right--large: 0;
$header-simple-search-secondary-results-top--large: rem-calc(42);
$header-simple-search-secondary-field-font-size: rem-calc(12);
$header-simple-search-secondary-field-font-size--large: rem-calc(12);
$header-simple-search-secondary-label-display--large: inline-block;
$header-simple-search-secondary-label-color--large: color(text-secondary);
$header-simple-search-secondary-label-font-size--large: rem-calc(12);
$header-simple-search-secondary-label-line-height--large: rem-calc(26);

$header-simple-search-button-width: rem-calc(16);
$header-simple-search-button-height: rem-calc(16);
$header-simple-search-button-width--large: rem-calc(24);
$header-simple-search-button-height--large: rem-calc(24);
$header-simple-search-button-margin: rem-calc(4 15 0 0);
$header-simple-search-button-icon-color: color(dark);

$header-simple-search-clear-button-margin: rem-calc(4 15 0 0);
$header-simple-search-clear-button-icon-color: color(light) !default; // !default added to override on local level
$header-simple-search-clear-button-background--large: color(dark);

$header-simple-search-inline-results-width: 100%;
$header-simple-search-inline-padding: rem-calc(0 15 8);
$header-simple-search-inline-background: color(dark);
$header-simple-search-inline-field-background: color(dark);
$header-simple-search-inline-input-group-background: color(dark);
$header-simple-search-inline-field-padding: 0;
$header-simple-search-inline-results-margin: rem-calc(8 0 0);
$header-simple-search-inline-results-margin--large: rem-calc(0);
$header-simple-search-inline-field-color: color(light);
$header-simple-search-inline-field-color--large: color(dark);
$header-simple-search-inline-label-color--medium-down: color(light);
$header-simple-search-inline-input-group-border-bottom: 1px solid color(light);
$header-simple-search-inline-input-group-border-bottom--large: none;
$header-simple-search-inline-button-icon-color: color(light);

$header-simple-search-clear-button-width--large: rem-calc(26);
$header-simple-search-clear-button-height--large: rem-calc(26);
$header-simple-search-label-color: color(disabled);
$header-simple-search-label-font-family: $font-secondary;
$header-simple-search-label-font-size: rem-calc(32);
$header-simple-search-label-padding: rem-calc(0 0 0 10);
$header-simple-search-label-padding--large: $header-simple-search-label-padding;

$header-simple-search-clear-button-background: color(alert);

$header-simple-search-field-font-family: $font-secondary;
$header-simple-search-field-font-family--large: $header-simple-search-field-font-family;
$header-simple-search-field-font-size: rem-calc(16);
$header-simple-search-field-font-size--large: rem-calc(32);
$header-simple-search-field-font-weight: bold;
$header-simple-search-field-font-weight--large: normal;

@import '@lora/05-components/header/header-simple-search';