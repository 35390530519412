$socialplus-line-emailotpverification-flex-flow: row wrap !default;
$socialplus-line-emailotpverification-flex-flow--large: row nowrap !default;
$socialplus-line-link-accounts-captcha-marign: 0 !default;
$socialplus-line-account-padding: rem-calc(0 15) !default;
$socialplus-line-account-padding--large: 0 !default;

@mixin apaclayer-components-socialplus-line {
    .l-account__line {
        padding: $socialplus-line-account-padding;

        @include breakpoint(large) {
            padding: $socialplus-line-account-padding--large;
        }
    }

    .c-socialplus-line-emailotpverification {
        flex-flow: $socialplus-line-emailotpverification-flex-flow;
    
        @include breakpoint(large) {
            flex-flow: $socialplus-line-emailotpverification-flex-flow--large;
        }
    }

    .c-link-line-accounts {
        .c-captcha {
            margin: $socialplus-line-link-accounts-captcha-marign;
        }
    }
}