@import "@root/05-components/loyalty/loyalty-reviews";

$loyalty-reviews-info-max-width: 100% !default;
$loyalty-reviews-info-margin-bottom: 2rem !default;

@mixin apaclayer-components-loyalty-reviews {
    .c-loyalty-reviews__info {
        max-width: $loyalty-reviews-info-max-width;
        margin-bottom: $loyalty-reviews-info-margin-bottom;
    }
}
