$product-tile-horizontal-name-font-family: $font-secondary;
$product-tile-horizontal-name-font-size: rem-calc(16);
$product-tile-horizontal-name-font-weight: normal;
$product-tile-horizontal-name-max-lines: 2;
$product-tile-horizontal-name-max-lines--large: 2;
$product-tile-horizontal-info-justify-content: space-between;
$product-tile-horizontal-info-margin: rem-calc(5 0 0);
$product-tile-horizontal-info-item-font: bold rem-calc(12) $font-tertiary;
$product-tile-horizontal-info-item-padding: rem-calc(0 30 0 0); // used on Discontinued Product Page
$product-tile-horizontal-info-item-padding--large: rem-calc(5 30 0 0); // used on Discontinued Product Page
$product-tile-horizontal-price-font: bold rem-calc(12) $font-tertiary;
$product-tile-horizontal-name-margin: rem-calc(0 0 10);
$product-tile-horizontal-name-margin--large: rem-calc(0 0 5);
$product-tile-horizontal-subtitle-margin: rem-calc(0 0 3);
$product-tile-horizontal-subtitle-margin--large: rem-calc(0 0 3);
$product-tile-horizontal-variation-margin: rem-calc(0 0 10);
$product-tile-horizontal-variation-margin--large: rem-calc(0);
$product-tile-horizontal-badge-left: rem-calc(5);
$product-tile-horizontal-badge-left--large: $product-tile-horizontal-badge-left;
$product-tile-horizontal-badge-top: rem-calc(5);
$product-tile-horizontal-badge-top--large: $product-tile-horizontal-badge-top;
$product-tile-horizontal-badge-max-width: rem-calc(20);
$product-tile-horizontal-badge-max-width--large: rem-calc(30);
$product-tile-horizontal-name-line-height: 1.4; // important not change, done in scope of NGLKIE-3758

@import '@lora/05-components/product/product-tile-horizontal';