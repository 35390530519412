$content-reassurance-padding: rem-calc(12 0);
$content-reassurance-padding--large: null;
$content-reassurance-image-margin--large: rem-calc(0 20 0 0);
$content-reassurance-text-font: #{rem-calc(14)} / 1.25 $font-tertiary;
$content-reassurance-text-font--large: #{rem-calc(12)} / 1.25 $font-tertiary;
$content-reassurance-text-text-transform: uppercase;
$content-reassurance-text-text-transform--large: uppercase;
$content-reassurance-image-max-width: rem-calc(40);
$content-reassurance-image-max-width--large: rem-calc(55);

@import '@lora/05-components/content/content-reassurance';