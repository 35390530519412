// Empty minicart
$minicart-empty-text-transform: none;
$minicart-empty-text-font: #{rem-calc(32)} / 1.2 $font-tertiary;
$minicart-empty-checkout-link-font: #{rem-calc(14)} / 1.714 $font-tertiary;
$minicart-empty-content-background: color(global-background);
$minicart-empty-width: rem-calc(720);
$minicart-empty-content-width: 40%;
$minicart-empty-recommendation-width: 60%;
$minicart-empty-content-padding: rem-calc(30 60);
$minicart-empty-padding: rem-calc(45 20);
$minicart-empty-title-text-transform: uppercase;

// Table minicart
$minicart-background: color(light);
$minicart-th-font: bold #{rem-calc(12)} / 1 $font-tertiary;
$minicart-th-padding: rem-calc(20 15 30);
$minicart-th-details-padding: rem-calc(20 15 30 32);
$minicart-td-thumbnail-padding: rem-calc(5 0 5 30);
$minicart-td-padding: rem-calc(0 15);
$minicart-td-price-font: bold #{rem-calc(12)} / 1 $font-tertiary;
$minicart-td-qty-font: bold #{rem-calc(12)} / 1 $font-tertiary;

// Minicart Product
$minicart-product-title-font: #{rem-calc(16)} / 1 $font-secondary;
$minicart-item-content-border-radius: $global-radius;
// Product Remove Icon
$minicart-remove-font: bold #{rem-calc(11)} / 2.5 $font-tertiary;
$minicart-remove-text-transform: capitalize;
$minicart-remove-color: color(text-secondary);
// Minicart Subtotal
$minicart-subtotal-background: color(primary);
$minicart-subtotal-title-color: color(primary);
$minicart-subtotal-price-color: color(primary);
$minicart-subtotal-title-font: bold #{rem-calc(12)} / 1 $font-tertiary;
$minicart-subtotal-price-font: bold #{rem-calc(12)} / 1 $font-tertiary;
$minicart-subtotal-title-text-transform: uppercase;
// Minicart Actions
$minicart-actions-background: color(global-background);
$minicart-actions-padding: rem-calc(25 15);
// Minicart Promotions
$minicart-promotions-border: none;
$minicart-promotions-margin: rem-calc(-1 0 0 0);
// Header
$minicart-header-border: 1px solid color(dark);
$minicart-header-padding: rem-calc(20 50 20 20);
// Footer
$minicart-footer-border: 1px solid color(dark);
$minicart-footer-box-shadow: none;
// Recommendation
$minicart-recommendation-padding: rem-calc(10 20 30);
$minicart-recommendation-background: color(light);

@import '@lora/05-components/minicart';