$locale-selector-background--medium-down: color(primary);
// Select
$locale-selector-select-color: color(light);
$locale-selector-select-color--large: color(light);
$locale-selector-select-arrow-color: color(light);
// Title
$locale-selector-title-color--large: color(dark);
$locale-selector-title-color: color(light);
$locale-selector-title-font: bold #{rem-calc(18)} / 1.4 $font-primary;
$locale-selector-title-font--large: $locale-selector-title-font;
$locale-selector-title-text-align: center;
$locale-selector-title-text-transform: none;
$locale-selector-title-text-transform--large: $locale-selector-title-text-transform;
// Description
$locale-selector-description-color--large: color(dark);
$locale-selector-description-color: color(light);
$locale-selector-description-text-align: center;
// Item link
$locale-selector-item-link-color: color(text);
$locale-selector-item-title-color: color(text);
// Item active link
$locale-selector-item-active-link-font: bold #{rem-calc(12)} / 1.4 $font-primary;
$locale-selector-item-active-link-font--large: $locale-selector-item-active-link-font;
/// Select secondary
$locale-selector-select-secondary-font: normal #{rem-calc(11)} / 1 $font-tertiary;
$locale-selector-select-secondary-padding: rem-calc(0 15 0 0);
$locale-selector-select-secondary-arrow-position: rem-calc(0);

@import '@lora/05-components/locale-selector';