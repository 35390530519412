$swatch-sizes: (
    default: (
        swatch-size: rem-calc(28),
        border-size: rem-calc(2)
    ),
    small: (
        swatch-size: rem-calc(19),
        border-size: rem-calc(1)
    ),
    medium: (
        swatch-size: rem-calc(34),
        border-size: rem-calc(3)
    ),
    large: (
        swatch-size: rem-calc(49),
        border-size: rem-calc(7)
    ),
    x-large: (
        swatch-size: rem-calc(56),
        border-size: rem-calc(4)
    )
);

$swatch-border-width: rem-calc(1);
$swatch-selected-border-padding: $swatch-border-width solid transparent;

@import '@lora/05-components/swatch';