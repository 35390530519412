/* stylelint-disable */
@import "@root/05-components/account/account-linked-accounts";

$account-login-button-width: rem-calc(240) !default;
$account-login-button-height: rem-calc(40) !default;
$account-login-button-padding: rem-calc(0) !default;

@mixin jplayer-components-account-linked-accounts {
    .c-account__sociallogin {

        .c-button {
            &.m-facebook:not([disabled]) {
                background-image:url("../../images/icons/icon-facebook.jpg");
            }

            &.m-line:not([disabled]) {
                background-image:url("../../images/icons/icon-line.jpg");
            }

            &.m-twitter:not([disabled]) {
                background-image:url("../../images/icons/icon-twitter.jpg");
            }

            &.m-yahoo:not([disabled]) {
                background-image:url("../../images/icons/icon-yahoo.jpg");
            }

            &:not([disabled]) {
                background-color: transparent !important;
                background-repeat: no-repeat !important;
                background-size: 100% !important;
                color: transparent !important;
                border-radius: unset;
                border: 1px solid transparent;
                width: $account-login-button-width !important;
                height: $account-login-button-height;
                padding: $account-login-button-padding !important;

                &::after, &::before{
                    display: none;
                }
            }
        }
    }
}
