// Text Suggestions
$header-suggestions-text-height: auto;
$header-suggestions-text-separator: none;
$header-suggestions-text-list-separator--large: 2px solid color(secondary);
$header-suggestions-text-match-font: bold #{rem-calc(14)} / 1.25 $font-primary;
$header-suggestions-text-match-font--large: $header-suggestions-text-match-font;
$header-suggestions-text-link-padding: rem-calc(8 0);
$header-suggestions-text-match-color: color(dark);
// Suggestions Title
$header-suggestions-title-padding: rem-calc(20 0 8);
$header-suggestions-title-font: bold #{rem-calc(14)} / 1.2 $font-tertiary;
$header-suggestions-title-font--large: bold #{rem-calc(14)} / 1.2 $font-tertiary;
$header-suggestions-title-text-transform: uppercase;
$header-suggestions-title-text-transform--large: uppercase;
// Suggestions View All
$header-suggestions-view-all-font: bold #{rem-calc(14)} / 1 $font-tertiary;
$header-suggestions-view-all-font--large: $header-suggestions-view-all-font;
$header-suggestions-view-all-color: color(text);
$header-suggestions-view-all-right: rem-calc(30);
$header-suggestions-view-all-top: rem-calc(20);
$header-suggestions-view-all-text-transform: none;
$header-suggestions-view-all-text-transform--large: $header-suggestions-view-all-text-transform;
$header-suggestions-content-only-view-all-top--large: rem-calc(20);
$header-suggestions-products-item-tile-name-font: bold #{rem-calc(16)} / 1.25 $font-secondary;
$header-suggestions-products-item-tile-name-font--large: $header-suggestions-products-item-tile-name-font;
$header-suggestions-products-item-tile-name-color: color(dark);
$header-suggestions-products-item-tile-name-color--large: $header-suggestions-products-item-tile-name-color;
$header-suggestions-products-description-max-lines: 1;
$header-suggestions-content-description-font: #{rem-calc(12)} / 1.2 $font-primary;
$header-suggestions-content-description-font--large: $header-suggestions-content-description-font;
$header-suggestions-content-link-align-items: flex-start;

// Product Suggestions
$header-suggestions-products-height: auto;
$header-suggestions-products-price-font: bold #{rem-calc(12)} / 1 $font-tertiary;
$header-suggestions-products-padding--large: rem-calc(20 30);
$header-suggestions-content-only-padding--large: rem-calc(20 30);
$header-suggestions-products-flex--large: 1 1 100%;
$header-suggestions-products-item-max-width--large: 50%;
$header-suggestions-products-image-margin: rem-calc(0 10 0 0);
$header-suggestions-products-separator: none;
$header-suggestions-products-link-padding: rem-calc(8 0);
$header-suggestions-products-name-font--large: bold #{rem-calc(16)} / 1.25 $font-secondary;
$header-suggestions-products-name-font-size: rem-calc(12);
$header-suggestions-products-name-line-height: 1.25;
$header-suggestions-products-name-max-lines: 2;
$header-suggestions-products-list-padding--large: rem-calc(30 125 0 0);
$header-suggestions-products-list-gap--large: rem-calc(30 125);

// Content Suggestions
$header-suggestions-content-separator: none;
$header-suggestions-content-height: auto;
$header-suggestions-content-image-margin: rem-calc(0 20 0 0);
$header-suggestions-content-link-padding: rem-calc(8 0);
$header-suggestions-content-padding--large: rem-calc(30 30 40);
$header-suggestions-content-item-padding--large: rem-calc(20 125 0 20);
$header-suggestions-content-name-font--large: #{rem-calc(12)} / 1.25 $font-secondary;
$header-suggestions-content-name-font: bold #{rem-calc(14)} / 1.25 $font-primary;
$header-suggestions-content-name-font--large: $header-suggestions-content-name-font;
$header-suggestions-content-name-color: color(primary);
$header-suggestions-content-name-color--large: $header-suggestions-content-name-color;

// Secondary view
$header-suggestions-secondary-padding--large: 0;
$header-suggestions-secondary-border--large: 2px solid color(secondary);
$header-suggestions-secondary-content-border-top--large: 2px solid color(secondary);
$header-suggestions-secondary-text-list-padding--large: rem-calc(20 30);
$header-suggestions-secondary-content-only-padding--large: rem-calc(30 30 40);
$header-suggestions-secondary-products-max-width--large: 100%;
$header-suggestions-secondary-products-list-gap--large: rem-calc(50);
$header-suggestions-aside-secondary-margin--large: rem-calc(25 17 40 28);
$header-suggestions-aside-secondary-width--large: rem-calc(220);
// Aside section
$header-suggestions-aside-padding: rem-calc(20 10 5);
$header-suggestions-aside-padding--large: rem-calc(20 10 10);
$header-suggestions-aside-margin: rem-calc(20 0 15 0);
// Aside search block
$header-suggestions-aside-title-color: color(text);
$header-suggestions-aside-title-font: #{rem-calc(14)} / 1.2 $font-tertiary;
$header-suggestions-aside-title-text-transform: uppercase;
$header-suggestions-aside-list-margin: rem-calc(0 0 20 20);
$header-suggestions-aside-list-margin--large: $header-suggestions-aside-list-margin;
$header-suggestions-aside-link-font: #{rem-calc(12)} / 1.7 $font-primary;
$header-suggestions-aside-link-padding: 0;

@import '@lora/05-components/header/header-suggestions';