@import "@root/05-components/form/check-field";

$form-check-field-switch-before-height: rem-calc(21.5);
$form-check-field-switch-before-width: rem-calc(48);
$form-check-field-switch-after-height: rem-calc(16);
$form-check-field-switch-after-width: rem-calc(16);
$form-check-field-switch-after-position--top: rem-calc(2.5);
$form-check-field-switch-before-position--top: rem-calc(0);
$form-check-field-switch-before-position--right: rem-calc(0);
$form-check-field-switch-after-position--right: rem-calc(5);
$form-check-field-switch-active-after-position--right: rem-calc(28);
$form-check-field-switch-checked-before-primary-background: #009ee2;
$form-check-field-switch-before-toggle-background: color(dark);
$form-check-field-switch-checked-before-light-background: color(light);
$form-check-field-switch-after-toggle-background: color(border);
$form-check-field-switch-checked-label-margin: rem-calc(30);

@mixin jplayer-components-form-check-field {
    .c-check-field.m-switch {
        .c-check-field__label {
            margin-bottom: $form-check-field-switch-checked-label-margin;

            &::before {
                background: $form-check-field-switch-after-toggle-background;
                right: $form-check-field-switch-before-position--right;
                top: $form-check-field-switch-before-position--top;
            }

            &::after {
                background: $form-check-field-switch-before-toggle-background;
                right: $form-check-field-switch-active-after-position--right;
            }
        }

        .c-check-field__input:checked + .c-check-field__label {
            &::before {
                background: $form-check-field-switch-checked-before-primary-background;
                right: $form-check-field-switch-before-position--right;
            }

            &::after {
                background: $form-check-field-switch-checked-before-light-background;
                right: $form-check-field-switch-after-position--right;
                top: $form-check-field-switch-after-position--top;
            }
        }
    }
}
