$footer-background: color(dark);
$footer-background--large: color(dark);
$footer-bottom-border-top: none;
$footer-bottom-border-top--large: none;
$footer-newsletter-margin: rem-calc(30 0 0);
$footer-top-layout-size: 'full';
$footer-bottom-inner-display: flex;
$footer-bottom-inner-flex-direction: column-reverse;
$footer-newsletter-captcha-text-text-align: center;
$footer-newsletter-captcha-link-hover-color: color(light);
$footer-newsletter-link-hover-color: color(light);
$footer-social-margin--large: rem-calc(20 -10 20 0);

@import '@lora/04-layout/footer';