$product-lineitem-highlight-padding: rem-calc(5 10 20);

// Title
$product-lineitem-highlight-title-font: bold #{rem-calc(12)} / #{rem-calc(15)} $font-tertiary;
$product-lineitem-highlight-title-margin: 0;
$product-lineitem-highlight-action-text-transform: capitalize;
$product-lineitem-highlight-action-text-transform--large: $product-lineitem-highlight-action-text-transform;

$product-lineitem-highlight-action-font: bold #{rem-calc(12)} / #{rem-calc(15)} $font-tertiary;
$product-lineitem-highlight-action-font--large: $product-lineitem-highlight-action-font;

@import "@lora/05-components/product/product-lineitem-highlight";