@import "@root/05-components/button";

$button-social-facebook-background: #34599f;
$button-social-line-background: #01b901;
$button-social-twitter-background: #1da1f4;
$button-social-yahoo-background: #fe0132;

// Social buttons
$button-social-list: (
    facebook: (
        icon: facebook,
        background: $button-social-facebook-background,
        border: none,
    ),
    twitter: (
        icon: twitter,
        background: $button-social-twitter-background,
        border: none,
    ),
    line: (
        icon: lineme,
        background: $button-social-line-background,
        border: none,
    ),
    yahoo: (
        icon: yahoo,
        background: $button-social-yahoo-background,
        border: none,
    ),
    google: (
        icon: google,
    ),
);
