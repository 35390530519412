// NGL variables
$footer-sticky-bar-padding: rem-calc(0 20);
$footer-sticky-bar-padding--large: $footer-sticky-bar-padding;
$footer-sticky-bar-item-color: color(light);
$footer-sticky-bar-item-hover-color: color(light);
$footer-sticky-bar-font: bold #{rem-calc(12)} $font-tertiary;
$footer-sticky-bar-font--large: $footer-sticky-bar-font;
$footer-sticky-bar-letter-spacing: rem-calc(1);
$footer-sticky-bar-letter-spacing--large: rem-calc(1);
$footer-sticky-bar-vertical-space: rem-calc(11);
$footer-sticky-bar-line-height: rem-calc(18);
$footer-sticky-bar-height: ($footer-sticky-bar-vertical-space * 2) + $footer-sticky-bar-line-height;
$footer-sticky-bar-height--large: $footer-sticky-bar-height;

// Kiehls variables
$footer-sticky-bar-icon-heart-vertical-align: middle;
$footer-sticky-bar-icon-heart-margin: rem-calc(0 5 0 0);
$footer-sticky-bar-icon-chat-vertical-align: middle;
$footer-sticky-bar-icon-chat-margin: rem-calc(0 10 0 0);
$footer-sticky-bar-item-gold-font-size: rem-calc(10);
$footer-sticky-bar-item-gold-color: color(text-tertiary);
$footer-sticky-bar-item-gold-hover-color: color(text-tertiary);

@import '@lora/05-components/footer/footer-sticky-bar'; //stylelint-disable-line