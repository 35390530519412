$rating-icon: 'star';
$rating-icon-margin: rem-calc(1);
$rating-color: color(secondary);
$rating-color-active: color(alert);
$rating-hover-color-active: $rating-color-active;
$rating-value-color: color(text);
$rating-icon-transition: none;
$rating-font-size: rem-calc(12);
// Secondary
$rating-large-font-size: rem-calc(22);
$rating-large-value-font-size: rem-calc(30);
$rating-large-value-font-size--large: rem-calc(50);
$rating-value-margin: rem-calc(0 10 0 3);
// Medium
$rating-medium-font-size: rem-calc(18);
$rating-medium-icon-margin: rem-calc(2);
$rating-medium-value-font-size--large: rem-calc(20);
$rating-medium-value-margin: rem-calc(5 10 0);
// Review Value
$rating-review-value-color: color(text-secondary);

@import '@lora/05-components/rating';