$tag-link-background: color(disabled);
$tag-link-border: 1px solid color(dark);
$tag-link-color: color(dark);
$tag-link-hover-background: color(dark);
$tag-link-hover-border: $tag-link-border;
$tag-link-hover-color: color(light);
$tag-link-active-background: color(dark);
$tag-font: #{rem-calc(10)} / 1 $font-tertiary;
$tag-font--large: $tag-font;
$tag-padding: rem-calc(5 10);
$tag-padding--large: $tag-padding;

$tag-secondary-font: #{rem-calc(11)} / 1 $font-primary;
$tag-secondary-font--large: $tag-secondary-font;
$tag-secondary-border: none;

@import "@lora/05-components/tag";