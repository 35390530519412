$product-tile-default-text-align: center;

// Product name
$product-tile-name-font-family: $font-secondary;
$product-tile-name-font-weight: bold;
$product-tile-name-font-size--large: rem-calc(20);
$product-tile-name-margin: rem-calc(8) 0;
$product-tile-name-margin--large: rem-calc(10) 0;
$product-tile-name-line-height: 1.4; // important not change, done in scope of NGLKIE-3381
$product-tile-name-line-height--large: $product-tile-name-line-height;
$product-tile-name-color-visited: color(text);

// Product description
$product-tile-description-font-family: $font-secondary;
$product-tile-description-font-weight: normal;
$product-tile-description-font-style: italic;
$product-tile-description-font-size: rem-calc(12);
$product-tile-description-font-size--large: rem-calc(14);
$product-tile-description-line-height: 1.4; // important not change, done in scope of NGLKIE-3381
$product-tile-description-line-height--large: $product-tile-description-line-height;
$product-tile-description-color: color(dark);
$product-tile-description-max-lines: 2;
$product-tile-description-max-lines--large: 2;
$product-tile-description-margin: rem-calc(0 0 10);
$product-tile-description-margin--large: $product-tile-description-margin;

// Product add to bag
$product-tile-add-bag-button-min-width: 100%;
$product-tile-add-bag-button-min-width--large: 100%;

$product-tile-price-font: #{rem-calc(16)} / 1 $font-tertiary;
$product-tile-price-font--large: #{rem-calc(20)} / 1 $font-tertiary;

$product-tile-info-single-line: false;
$product-tile-rating-empty-display: block;
$product-tile-rating-height: rem-calc(12) !default;
$product-tile-rating-align: center;
$product-tile-rating-align--large: center;
$product-tile-info-item-align: center;
$product-tile-info-item-align--large: center;
$product-tile-price-padding: rem-calc(25 0 0);
$product-tile-price-padding--large: rem-calc(13 0 0);
$product-tile-rating-padding: 0;
$product-tile-variation-group-margin: rem-calc(15 0 0);
$product-tile-variation-group-margin--large: rem-calc(15 0 0);
$product-tile-variations-margin--large: rem-calc(0 24);
$product-tile-variations-width--large: auto;
$product-tile-variations-fullwidth-margin: 0;

// Tile Secondary
$product-tile-secondary-text-align: center;
$product-tile-secondary-text-align--large: $product-tile-secondary-text-align;

/// Tile Secondary variations
$product-tile-secondary-variations-width: auto;
$product-tile-secondary-variations-width--large: $product-tile-secondary-variations-width;

// Product actions
$product-tile-actions-margin: rem-calc(15 0 0);
$product-tile-actions-padding: rem-calc(0 1);
$product-tile-actions-placeholder-height: rem-calc(40);
$product-tile-actions-placeholder-height--large: $product-tile-actions-placeholder-height;

// Product badge
$product-tile-badge-left: rem-calc(5);
$product-tile-badge-left--large: rem-calc(10);
$product-tile-badge-top: rem-calc(5);
$product-tile-badge-top--large: rem-calc(10);
$product-tile-badge-max-width: rem-calc(40);
$product-tile-badge-max-width--large: 50%;

// Product thumbnail
$product-tile-thumbnail-margin: rem-calc(0 0 8);
$product-tile-thumbnail-on-hover-badge-opacity: 0;

// Product wishlist
$product-tile-wishlist-right: rem-calc(12);
$product-tile-wishlist-top: rem-calc(12);

$product-tile-selectable-wishlist-top: rem-calc(20);
$product-tile-selectable-wishlist-right: rem-calc(20);

// Tile Selectable
$product-tile-selectable-secondary-active-border-color: color(disabled);
$product-tile-badge-width--large: 100%;

// Product rating star
$product-tile-rating-star-margin: rem-calc(0 0 0 1);

$product-tile-swatch-selected-font-weight: 500;
$product-tile-swatch-selected-color: color(text-secondary);

$product-tile-add-bag-text-font: rem-calc(16) / 1 $font-tertiary;
$product-tile-add-bag-text-font--large: $product-tile-add-bag-text-font;
$product-tile-add-bag-text-transform: uppercase;
$product-tile-add-bag-text-transform--large: $product-tile-add-bag-text-transform;

@import '@lora/05-components/product/product-tile';