@import "@root/05-components/progress-bar";

$progress-bar-point-price-white-space: nowrap !default;

@mixin jp-layer-components-progress-bar {
    .c-progress-bar__points {
        .c-progress-bar__point-price {
            white-space: $progress-bar-point-price-white-space;
        }
    }
}
