$header-navigation-item-title-level-1-font-size: rem-calc(16);
$header-navigation-item-title-level-1-font-size--large: rem-calc(16);
$header-navigation-link-level-1-padding--large: rem-calc(18 12);
$header-navigation-link-color: inherit;
$header-navigation-link-level-1-color: inherit;
$header-navigation-link-level-1-color--large: inherit;
$header-navigation-link-level-1-hover-color: inherit;
$header-navigation-link-level-1-font-family: $font-primary;
$header-navigation-link-level-1-font-family--large: $font-tertiary;
$header-navigation-item-title-level-1-color--large: color(light);
$header-navigation-link-level-1-font-size: rem-calc(16);
$header-navigation-item-title-level-1-expanded-font: bold #{rem-calc(16)} / 1.4 $font-primary;
$header-navigation-item-title-level-2-expanded-color: color(primary);
$header-navigation-item-title-level-2-background: color(global-background);
$header-navigation-link-level-2-font-size: rem-calc(14);
$header-navigation-link-level-2-color: color(primary);
$header-navigation-link-level-2-font-family: $font-primary;
$header-navigation-link-level-2-font-family--large: $font-tertiary;
$header-navigation-item-title-level-2-font--large: #{rem-calc(14)} / 2 $font-tertiary;
$header-navigation-link-level-2-text-transform: uppercase;
$header-navigation-link-level-2-text-transform--large: uppercase;
$header-navigation-link-level-1-text-align--large: center;
$header-navigation-item-title-level-2-expanded-background: transparent;
$header-navigation-item-title-level-3-background: transparent;

$header-navigation-item-button-level-1-focus-icon-color: color(light);

$header-navigation-content-title-font--large: #{rem-calc(16)} / 1.6 $font-secondary;
$header-navigation-content-title-text-transform--large: uppercase;
$header-navigation-content-title-margin--large: rem-calc(10 0 0);
$header-navigation-flyout-element-show-arrow: true;
$header-navigation-flyout-element-background--large: color(global-background);

$header-navigation-link-level-1-hover-color--large: color(light);
$header-navigation-link-level-1-hover-text-decoration--large: none;
$header-navigation-link-level-2-font-size--large: rem-calc(14);

//// Hover link level 3
$header-navigation-link-level-3-hover-color: color(dark);
$header-navigation-link-level-3-hover-font-weight: bold;
$header-navigation-link-level-3-hover-text-decoration: none;

// Navigation main
$header-navigation-container-grid-main-list-grid-column--xlarge: 1;
// Navigation container content
$header-navigation-container-grid-content-grid-column--xlarge: 2;

$header-navigation-container-grid-main-list-grid-row--xlarge: 2 / span 2;
$header-navigation-item-level-2-all-link-font: #{rem-calc(14)} / 1.4 $font-primary;
$header-navigation-item-level-2-all-link-font--large: #{rem-calc(14)} / 1.2 $font-tertiary;
$header-navigation-item-level-2-all-link-text-transform: uppercase;
$header-navigation-item-level-2-all-link-text-transform--large: $header-navigation-item-level-2-all-link-text-transform;
$header-navigation-item-level-2-all-link-text-decoration: underline;
$header-navigation-item-level-2-all-link-text-decoration--large: $header-navigation-item-level-2-all-link-text-decoration;

@import '@lora/05-components/header/header-navigation';