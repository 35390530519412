$tabs-secondary-controls-offset: map-get($grid-container-padding, small) / 2;
$tabs-content-margin--large: 0;
$tabs-tab-padding: rem-calc(0 10 12);
$tabs-tab-color: color(organism-background);
$tabs-tab-active-color: color(text);
$tabs-tab-font: bold #{rem-calc(12)} / 1 $font-tertiary;
$tabs-tab-font--large: bold #{rem-calc(16)} / 1.5 $font-tertiary;
$tabs-tab-active-font--large: $tabs-tab-font--large;
$tabs-tab-active-after-border-bottom: 2px solid color(primary);
$tabs-tab-text-transform: uppercase;

// Tabs secondary
$tabs-secondary-controls-width--large: inherit;
$tabs-secondary-controls-background: color(transparent);
$tabs-secondary-content-background: color(transparent);
$tabs-secondary-tab-flex--large: 1 0 auto;
$tabs-secondary-tab-text-align: center;
$tabs-secondary-tab-padding: rem-calc(12 10);
$tabs-secondary-tab-padding--large: rem-calc(12 20);
$tabs-secondary-tab-font-size: rem-calc(14);
$tabs-secondary-tab-font-size--large: $tabs-secondary-tab-font-size;
$tabs-secondary-control-items-justify-content: center;
$tabs-secondary-controls-margin: 0 -#{$tabs-secondary-controls-offset} rem-calc(10);
$tabs-secondary-controls-margin--large: rem-calc(0 0 10);

// Tabs tertiary
$tabs-tertiary-controls-background-color: color(transparent);
$tabs-tertiary-control-items-padding: rem-calc(0);
$tabs-tertiary-tab-color: color(text-secondary);
$tabs-tertiary-tab-active-color: color(text);
$tabs-tertiary-tab-font: #{rem-calc(14)} / 1 $font-tertiary;
$tabs-tertiary-tab-text-transform: uppercase;

$tabs-tab-plain-active-background: color(disabled);

@import "@lora/05-components/tabs";