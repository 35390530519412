$header-user-customer-text-transform: uppercase;
$header-user-text-text-transform: uppercase;
$header-user-list-margin: rem-calc(0 30);
$header-user-list-item-color: color(primary);
$header-user-list-item-link-color: color(primary);
$header-user-link-account-icon-color: color(light);
$header-user-link-account-icon-hover-color: color(light);
$header-user-list-item-loyalty-status-icon: 'loyalty';
$header-user-list-item-loyalty-status-icon-size: rem-calc(25);
$header-user-list-item-icon-svg-margin: rem-calc(0 10 0 0);
$header-user-list-item-loyalty-status-icon-color: color(loyalty);
$header-user-list-item-loyalty-status-icon-hover-color: $header-user-list-item-loyalty-status-icon-color;

@import '@lora/05-components/header/header-user';