$section-title-font: normal #{rem-calc(24)} / 1 $font-secondary;
$section-title-font--large: normal #{rem-calc(32)} / 1 $font-secondary;
$section-title-text-transform: none;
$section-title-text-align: center;
$section-title-text-align--large: center;
$section-description-margin: rem-calc(14 0 16);
$section-description-font: #{rem-calc(14)} / 1.5 $font-primary;
$section-description-text-align: center;

$section-title-secondary-font: #{rem-calc(30)} / 1.2 $font-secondary;
$section-title-secondary-font--large: #{rem-calc(40)} / 1.2 $font-secondary;
$section-title-secondary-text-transform: uppercase;
$section-title-secondary-text-transform--large: $section-title-secondary-text-transform;

// Headings
$section-headings-max-width: 100%;
$section-headings-max-width--large: $section-headings-max-width;

@import '@lora/05-components/section';