@import '@root/04-layout/footer';

$footer-background: color(light);
$footer-background--large: $footer-background;
$footer-top-background: color(light);
$footer-top-background--large: $footer-top-background;
$footer-newsletter-color: color(dark);
$footer-newsletter-color--large: $footer-newsletter-color;
$footer-newsletter-link-color: color(dark);
$footer-newsletter-link-color--large: color(dark);
$footer-newsletter-link-hover-color: color(dark);
$footer-newsletter-signup-title-color: color(dark);
$footer-newsletter-signup-title-color--large: $footer-newsletter-signup-title-color;
$footer-newsletter-captcha-text-color: color(dark);
$footer-newsletter-captcha-text-color--large: $footer-newsletter-captcha-text-color;
$footer-error-color: color(alert);
$footer-error-color--large: $footer-error-color;
$footer-newsletter-captcha-link-color: color(dark);
$footer-newsletter-captcha-link-color--large: $footer-newsletter-captcha-link-color;
$footer-newsletter-captcha-link-hover-color: $footer-newsletter-captcha-link-color;
$footer-localeselector-margin: 0;
$footer-social-margin--large: rem-calc(0 -10);
