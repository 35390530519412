$header-guaranteed-logo-image--large: rem-calc(65) !default;
$header-guaranteed-logo-image: rem-calc(50) !default;
$header-guaranteed-logo-order--large: 4 !default;
$header-cart-icon-padding: rem-calc(10 10 0) !default;
$header-cart-icon-order--large: 3;
$header-signup-link-order--large: 1 !default;
$header-myaccount-link-order--large: 2 !default;
$header-top-nav-right-padding--large: rem-calc(15 0 0);
$header-top-nav-link-hover-text-decoration: underline;
$header-guaranteed-logo-margin: rem-calc(0 0 0 5);
$header-guaranteed-logo-padding: rem-calc(0 0 0 5);

@mixin jplayer-layout-header {
    @include lora-layout-header();

    .m-guaranteed-logo {
        order: $header-guaranteed-logo-order--large;
        margin: $header-top-nav-item-margin--large;
        padding: $header-top-nav-item-padding--large;

        @include breakpoint(large down) {
            margin: $header-guaranteed-logo-margin;
            padding: $header-guaranteed-logo-padding;
        }

        .c-guaranteed-logo__image {
            width: $header-guaranteed-logo-image;

            @include breakpoint(xlarge) {
                width: $header-guaranteed-logo-image--large;
            }
        }
    }

    .m-email-signup {
        @include breakpoint(xlarge) {
            order: $header-signup-link-order--large;
        }
    }

    .m-minicart {
        padding: $header-cart-icon-padding;

        @include breakpoint(xlarge) {
            padding: $header-cta-padding;
            order: $header-cart-icon-order--large;
        }
    }

    .m-account {
        @include breakpoint(xlarge) {
            order: $header-myaccount-link-order--large;
        }
    }

    .c-abandonedcard-alert {
        .c-alert.m-icon.m-warning {
            background: #fff0d3;

            &::before {
                background-image: url('../../images/icons/alert-veritrans.svg');
            }
        }
    }
}

//stylelint-disable
@import "@root/04-layout/header";