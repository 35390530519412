$content-panel-background: color(light);
$content-panel-font-color: color(text);
$content-panel-close-color: color(text);
$content-panel-font: bold #{rem-calc(12)} / 1.5 $font-primary;
$content-panel-text-align: center;

$content-panel-colors: (
    "error": color(alert),
    "warning": color(disabled),
    "success": color(success),
    "info": color(global-background)
);

@import '@lora/05-components/content-panel';