$product-badge-image-max-width: rem-calc(52);
$product-badge-image-max-width--large: 45%;
$product-badge-title-font: bold #{rem-calc(12)} / 1.2 $font-primary;
$product-badge-title-font--large: $product-badge-title-font;
$product-badge-title-text-transform: uppercase;
$product-badge-title-text-decoration: none;
$product-badge-title-shadow: none;

$product-badge-title-background: none;
$product-badge-title-color: color(dark);
$product-badge-title-padding: 0;
$product-badge-title-border-radius: 0;

$product-badge-title-variations-font: #{rem-calc(10)} / 1 $font-tertiary;
$product-badge-title-variations-border-radius: rem-calc(2);
$product-badge-title-variations-padding: rem-calc(3 7);

@import '@lora/05-components/product/product-badge';