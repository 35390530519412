
                            $is-app_ng_jp-enabled: true;
                            @import '@root/04-layout/header';

$header-top-nav-right-padding--large: rem-calc(35 0 0 30);
$header-cart-icon-padding: 0;
$header-guaranteed-logo-image: rem-calc(30);
$header-top-nav-left-align-self--large: center;
$header-top-nav-left-padding--large: 0;
$header-account-link-icon-height: rem-calc(32) !default;
$header-account-link-icon-width: rem-calc(16) !default;
$header-account-link-icon: user !default;
$header-account-link-icon-color: color(light) !default;
$header-account-link-icon-size: 100% !default;
$header-account-link-icon-position: left center !default;
$header-account-link-icon-repeat: no-repeat !default;
$header-sticked-search-button-margin: 0 14px;
$header-search-button-padding: rem-calc(10 5);

@mixin kiejp-layout-header {
    .c-header-account {
        display: block;
        margin-left: 12px;

        @include breakpoint(xlarge) {
            display: none;
        }

        .c-account-link {
            align-items: center;
            display: flex;

            &::before {
                @include svg-icon($header-account-link-icon, $header-account-link-icon-color, $header-account-link-icon-size, $header-account-link-icon-position, $header-account-link-icon-repeat);

                content: '';
                display: block;
                height: $header-account-link-icon-height;
                width: $header-account-link-icon-width;
            }
        }
    }

    .l-header__top-nav-item {
        &.m-account::after {
            content: none !important; // stylelint-disable-line declaration-no-important
        }
    }
}

                        