$progress-bar-height: rem-calc(10);
$progress-bar-border: none;
$progress-bar-background-color: color(disabled);
$progress-bar-border-radius: inherit;
$progress-bar-before-active-icon-color: color(success);
$progress-bar-before-active-background-color: color(success);
$progress-bar-point-description-active-font: #{rem-calc(12)} / 1.4 $font-primary;
// Secondary
$progress-bar-value-secondary-background-color: color(success);
$progress-bar-secondary-active-background-color: color(success);
// Icon, before
$progress-bar-before-size: rem-calc(22);
$progress-bar-before-icon-color: color(success);
$progress-bar-before-active-icon-color: color(light);
$progress-bar-before-icon-size: rem-calc(10);
$progress-bar-before-active-icon-size: rem-calc(10);
$progress-bar-value-background-color: color(success);

$progress-bar-point-price-top: rem-calc(30);
$progress-bar-point-price-color: color(text-secondary);
$progress-bar-point-descriptions-margin: rem-calc(50 0 0);

@import '@lora/05-components/progress-bar';