$content-tile-margin--large: rem-calc(0 0 30);

/// Space between sections on horizontal position
$content-tile-gutter: (
    default: (
        small: rem-calc(16),
        large: rem-calc(20),
    ),
    large: (
        small: rem-calc(20),
        large: rem-calc(130),
    ),
);

/// Space between sections on vertical position
$content-tile-stack-gutter: rem-calc(10);

$content-tile-link-text-decoration: underline;

/// Title
$content-tile-title-max-lines: 2;
$content-tile-title-line-height: 1.33;
$content-tile-title-line-height--large: $content-tile-title-line-height;
$content-tile-title-margin: rem-calc(0 0 10);
$content-tile-title-margin--large: rem-calc(0 0 10);
$content-tile-title-font-size: rem-calc(18);
$content-tile-title-font-size--large: rem-calc(20);
$content-tile-title-font: #{$content-tile-title-font-size} / $content-tile-title-line-height $font-secondary;
$content-tile-title-font--large: #{$content-tile-title-font-size--large} / $content-tile-title-line-height--large $font-secondary;
$content-tile-title-secondary-font: #{rem-calc(24)} / 1.2 $font-secondary;
$content-tile-title-secondary-font--large: #{rem-calc(32)} / 1.2 $font-secondary;
$content-tile-title-text-transform: none;
$content-tile-title-text-transform--large: none;
/// Label
$content-tile-large-label-font: bold #{rem-calc(14)} / 1.25 $font-tertiary;
$content-tile-large-label-font--large: $content-tile-large-label-font;
$content-tile-label-color: color(primary);
$content-tile-label-color--large: $content-tile-label-color;
/// Description
$content-tile-description-max-lines: 2;
$content-tile-description-line-height: 1.5;
$content-tile-description-line-height--large: 1.7;
$content-tile-description-font-size: rem-calc(14);
$content-tile-description-font-size--large: rem-calc(14);
$content-tile-description-font: #{$content-tile-description-font-size} / $content-tile-description-line-height $font-primary;
$content-tile-description-font--large: #{$content-tile-description-font-size--large} / $content-tile-description-line-height--large $font-primary;
$content-tile-description-margin: rem-calc(10 0);
$content-tile-description-margin--large: $content-tile-description-margin;
/// Title Large
$content-tile-large-title-font: normal #{rem-calc(24)} / 1.2 $font-secondary;
$content-tile-large-title-font--large: normal #{rem-calc(32)} / 1.2 $font-secondary;
$content-tile-large-title-text-transform: none;
$content-tile-large-description-margin: rem-calc(15 0 25);
$content-tile-large-description-margin--large: $content-tile-large-description-margin;
/// Tile Featured
$content-tile-featured-title-font: $content-tile-large-title-font;
$content-tile-featured-title-font--large: $content-tile-large-title-font--large;
$content-tile-featured-title-margin: rem-calc(10 0 20);
$content-tile-featured-title-margin--large: rem-calc(10 0 15);
/// Description Large
$content-tile-large-description-font: #{rem-calc(16)} / 1.5 $font-primary;
$content-tile-large-description-font--large: #{rem-calc(16)} / 1.5 $font-primary;
// Tile Content
$content-tile-label-margin: rem-calc(30 0 15);
$content-tile-label-margin--large: rem-calc(30 0 15);
$content-tile-label-font: bold #{rem-calc(16)} / 1.25 $font-tertiary;
$content-tile-label-font--large: bold #{rem-calc(16)} / 1.25 $font-tertiary;
$content-tile-label-text-transform: capitalize;
$content-tile-label-text-transform--large: uppercase;
// Content info
$content-tile-info-margin: rem-calc(15 0 0);
$content-tile-info-margin--large: rem-calc(10 0 0);
/// Date
$content-tile-date-font: italic #{rem-calc(12)} / 1.4 $font-secondary;
$content-tile-date-font--large: $content-tile-date-font;
// Tile Subtitle
$content-tile-subtitle-font: bold #{rem-calc(20)} / 1.2 $font-secondary;
$content-tile-subtitle-font--large: $content-tile-subtitle-font;

@import '@lora/05-components/content-tile';