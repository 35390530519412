$header-background: color(dark);
$header-main-area-padding: rem-calc(12 0);
$header-main-area-padding--large: 0;
$header-bottom-background--large: inherit;
$header-logo-text-align: center;
$header-logo-max-width: rem-calc(140);
$header-logo-max-width--large: rem-calc(236);
$header-logo-justify-content: center;
$header-sticked-logo-max-width: rem-calc(140);
$header-sticked-logo-max-width--large: rem-calc(150);
$header-sticked-background: inherit;
$header-sticked-top-nav-link-title-level-1-color: inherit;
$header-sticked-top-nav-link-title-level-1-color--large: color(light);
$header-sticked-top-nav-link-title-level-1-hover-color: color(text);
$header-sticked-top-nav-link-title-level-1-hover-color--large: color(global-background);
$header-sticked-minicart-icon-color: color(light);
$header-sticked-top-nav-item-padding--large: rem-calc(0);
$header-sticked-top-nav-item-search-width--large: auto;
$header-sticked-top-nav-item-minicart-order--large: 2;
$header-sticked-search-button-icon-color: color(light);
$header-sticked-minicart-link-margin--large: rem-calc(0 0 0 10);
$header-sticked-search-button-active-background--large: color(dark);
$header-sticked-search-button-active-color--large: color(light);
$header-sticked-search-button-active-after-background-color--large: color(light);

$header-search-button-icon-width: rem-calc(14);
$header-search-button-icon-height: rem-calc(14);
$header-search-button-icon-active-height: $header-search-button-icon-height;
$header-search-button-icon-color: color(light);
$header-search-button-icon-color--large: color(dark);
$header-search-button-opened-background: color(light);
$header-search-button-active-icon-color: color(dark);
$header-search-button-sticky-only-display: block;
$header-search-button-sticky-only-display--large: none;
$header-search-button-sticky-only-visibility: hidden;
$header-search-button-sticky-only-visibility--large: $header-search-button-sticky-only-visibility;
$header-search-button-background--large: color(light);
$header-search-button-color--large: color(dark);
$header-search-button-padding--large: rem-calc(5 10);
$header-search-button-height: auto;
$header-search-button-font: #{rem-calc(14)} / 1 $font-tertiary;
$header-search-button-text-transform: none;
$header-search-button-width--large: rem-calc(135);
$header-search-button-background-hover--large: color(light);
$header-search-button-display--large: flex;
$header-search-button-justify-content--large: space-between;
$header-search-button-align-items--large: center;

// Header mobile elements order
$header-logo-order: 3;
$header-search-cta-order: 6;
$header-localization-order: 1;
$header-cart-order: 4;
$header-hamburger-order: 5;
$header-sticked-search-cta-mobile-order: 2;

$header-top-nav-item-font-family: $font-tertiary;
$header-top-nav-item-font-size: rem-calc(11);
$header-top-nav-item-color: color(light);
$header-top-nav-item-hover-color: color(element-background);
$header-top-nav-item-account-display--large: none;
$header-top-nav-item-separator-color: color(primary-active);
$header-sticked-top-nav-item-level-1-max-width--large: none;
$header-top-nav-right-align-items--large: center;
$header-top-nav-link-text-transform: uppercase;
$header-top-nav-item-minicart-padding--large: rem-calc(0 0 0 12);
$header-top-nav-left-padding: rem-calc(0 0 0 10);
$header-top-nav-left-padding--large: rem-calc(35 0 0);
$header-search-button-sticky-only-display: none;
$header-top-nav-storeslink-padding: rem-calc(7);
$header-search-button-padding: rem-calc(5 5 10);
$header-top-nav-item-localization-color: color(light);
$header-hamburger-align-items--large: center;
$header-suggestions-content-description-color: color(text-secondary);
$header-search-button-active-border--large: 3px solid color(disabled);
$header-search-button-border--large: 3px solid color(light);
$header-sticked-search-button-active-border--large: none;
$header-sticked-search-button-border--large: none;
$header-search-button-label-display--large-down: block;
$header-search-cta-mobile-button-label-color: color(light);
$header-search-cta-mobile-button-label-margin: rem-calc(0 0 0 7);
$header-search-cta-display: flex;
$header-search-cta-flex: 1 1 100%;

$header-search-cta-mobile-button-display: flex;
$header-search-cta-mobile-button-border-bottom: 1px solid color(light);
$header-search-cta-mobile-button-margin: rem-calc(0 14);
$header-search-cta-mobile-button-flex-grow: 1;

$header-sticked-search-button-width: auto;
$header-sticked-search-button-border-bottom: none;
$header-sticked-search-button-margin: 0;
$header-sticked-search-cta-mobile-flex: 0;
$header-search-button-width: 100%;

$header-search-button-active-border--large: 3px solid color(disabled);
$header-search-button-border--large: 3px solid color(light);
$header-sticked-search-button-active-border--large: none;
$header-sticked-search-button-border--large: none;

$header-hamburger-border--large: none;
$header-hamburger-border-width--large: none;
$header-search-button-flex-direction--large: row-reverse;

@import '@lora/04-layout/header';