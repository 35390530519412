$footer-nav-columns: 6 of 12;

$footer-nav-list-level1-margin: rem-calc(30 0 20);
$footer-nav-list-level1-margin--large: rem-calc(30 0 20);
$footer-nav-link-level1-font: #{rem-calc(14)} / 1.25 $font-tertiary;
$footer-nav-link-level1-font--large: bold #{rem-calc(14)} / 1.25 $font-tertiary;
$footer-nav-link-level1-text-transform: uppercase;
$footer-nav-link-level1-text-transform--large: uppercase;
$footer-nav-list-level2-margin: rem-calc(15 0 0);
$footer-nav-link-level2-font: #{rem-calc(14)} / 1.9 $font-primary;

@import '@lora/05-components/footer/footer-nav';