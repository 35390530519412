/// Info alert
/// @type Map
$alert-info: (
    background: color(success-secondary),
    border: 1px solid color(success-secondary),
    color: color(text),
    icon: 'info',
    icon-color: color(success),
);

/// Success alert
/// @type Map
$alert-success: (
    background: color(success-secondary),
    border: 1px solid color(success-secondary),
    color: color(success),
    icon: 'success',
    icon-color: color(success)
);

/// Warning alert
/// @type Map
$alert-warning: (
    background: color(warning-secondary),
    border: 1px solid color(warning-secondary),
    color: color(warning),
    icon: 'alert',
    icon-color: color(warning)
);

/// Error alert
/// @type Map
$alert-error: (
    background: color(error-secondary),
    border: 1px solid color(error-secondary),
    color: color(alert),
    icon: 'error',
    icon-color: color(alert)
);

$alert-margin: rem-calc(10 0);
$alert-margin--large: $alert-margin;
$alert-padding: rem-calc(10);
$alert-padding--large: rem-calc(15 35);

$alert-box-shadow: none;
$alert-border-radius: 0;
$alert-primary-has-border: true;
// Alert icon
$alert-icon-margin: rem-calc(0 8 0 0);
$alert-icon-margin--large: $alert-icon-margin;
// Background image
$alert-background-image-background: url('../#{$global-image-path}/reward/cart-reward-point-status-mobile.jpg') 0 0/cover repeat-x;
$alert-background-image-background--large: url('../#{$global-image-path}/reward/cart-reward-point-status-desktop.jpg') 0 0/contain repeat-x;

@import "@lora/05-components/alert"; //stylelint-disable-line