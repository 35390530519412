$loyalty-customer-info-tier-container-width: null !default;
$loyalty-customer-info-tier-container-font-size: null !default;
$loyalty-customer-info-tier-container-display: null !default;

@mixin apaclayer-components-loyalty-customer-info {
    .c-loyalty-customer__points-info {
        width: $loyalty-customer-info-tier-container-width;
        font-size: $loyalty-customer-info-tier-container-font-size;
        display: $loyalty-customer-info-tier-container-display;
    }
}