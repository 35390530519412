$header-hamburger-asset-link-may-we-help-you-icon: customer-service;
$header-hamburger-button-icon-height: rem-calc(22);
$header-hamburger-button-icon-width: rem-calc(20);
$header-hamburger-icon-color: color(light);
$header-hamburger-item-icon-color: color(primary);
$header-hamburger-button-background: color(primary);
$header-hamburger-item-link-padding: rem-calc(12 0);
$header-hamburger-item-link-transform: uppercase;
$header-hamburger-item-color: color(text);
$header-hamburger-button-active-color: color(light);
$header-hamburger-content-transition: transform 400ms ease-in-out;

@import '@lora/05-components/header/header-hamburger';