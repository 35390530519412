$header-user-menu-padding: rem-calc(16);
$header-user-menu-font: #{rem-calc(12)} / 1.4 $font-tertiary;
$header-user-menu-title-flex-direction: row;
$header-user-menu-title-font: #{rem-calc(12)} / 1.4 $font-tertiary;
$header-user-menu-title-name-text-transform: uppercase;
$header-user-menu-sign-in-text-transform: uppercase;
$header-user-menu-sign-in-text-decoration: none;
$header-user-menu-content-link-font-size: rem-calc(16);
$header-user-menu-content-link-font-family: $font-tertiary;
$header-user-menu-content-link-font-weight: bold;
$header-user-menu-content-link-text-transform: uppercase;
$header-user-menu-content-link-logout-background: color(primary);
$header-user-menu-secondary-link-font-size: rem-calc(16);
$header-user-menu-secondary-link-padding: rem-calc(20 16);
$header-user-menu-align-items: center;
$header-user-menu-background: color(global-background);
$header-user-menu-border: 1px solid color(disabled);
$header-user-menu-sign-in-text-decoration: underline;

$header-user-menu-back-button-width: 100%;
$header-user-menu-content-link-logout-hover-background: $header-user-menu-content-link-logout-background;
$header-user-menu-back-button-font: #{rem-calc(14)} / 1.2 $font-primary;

$header-user-menu-logout-link-color: color(text);
$header-user-menu-authenticated-justify-content: center;
$header-user-menu-content-title-font-family--medium-down: $font-secondary;
$header-user-menu-content-title-font-size: calc-rem(24);

@import '@lora/05-components/header/header-user-menu';