$add-to-wishlist-padding: rem-calc(7);
$add-to-wishlist-padding--large: $add-to-wishlist-padding;
$add-to-wishlist-icon-size: rem-calc(22);
$add-to-wishlist-icon-size--large: rem-calc(22);
$add-to-wishlist-icon-color: color(primary);
$add-to-wishlist-icon-hover-color: color(alert);
$add-to-wishlist-icon-active-color: color(alert);
$add-to-wishlist-background: none;
$add-to-wishlist-box-shadow: none;

@import '@lora/05-components/add-to-wishlist';