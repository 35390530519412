@import "@root/05-components/cart/cart-summary-table";

.l-cart__aside {
    .c-cart-summary-table {
        .c-field__error-message {
            word-break: break-word;
        }
    }
}

.c-cart__section {
    .c-cart-coupon__form {
        .c-field__error-message {
            word-break: break-word;
        }
    }
}