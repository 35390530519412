$header-minicart-icon-height: rem-calc(22);
$header-minicart-icon-width: rem-calc(22);
$header-minicart-icon-qty-font-size: rem-calc(10);
$header-minicart-icon-qty-color: color(text);
$header-minicart-icon-qty-sticky-color--large: color(text);
$header-minicart-icon-qty-bottom: 3px;
$header-minicart-icon-qty-right: 11px;
$header-minicart-icon-qty-background: transparent;
$header-minicart-icon-qty-sticky-background--large: transparent;
$header-minicart-icon-qty-sticky-bottom--large: -2px;
$header-minicart-icon-qty-sticky-right--large: 1px;

$header-minicart-icon-link-color: color(light);
$header-minicart-icon-link-hover-color: color(element-background);
$header-minicart-icon-hover-color: color(element-background);
$header-minicart-icon-color: color(light);
$header-minicart-icon-color--large: color(light);
$header-minicart-icon-label-margin--large: rem-calc(0 3 0 8);
$header-minicart-icon-svg-margin--large: 0;

@import '@lora/05-components/header/header-minicart-icon';