$form-check-field-label-font-size: rem-calc(12);
$form-check-field-label-side-margin: rem-calc(6);
// Radiobuttons
$form-check-field-radio-control-margin-top: 0;
// Checkboxes
$form-check-field-checkbox-checked-control-background: color(primary);
$form-check-field-checkbox-control-size: rem-calc(16);
$form-check-field-checkbox-checked-icon-position--top: rem-calc(3);
$form-check-field-checkbox-checked-icon-position--left: rem-calc(3);
// Checked state
$form-check-field-checkbox-checked-icon: tick;
// Error state
$form-check-field-error-control-color: null;
// Switch
$form-check-field-switch-checked-before-background: color(secondary);
$form-check-field-switch-checked-after-background: color(primary-active);
/// Switch before
$form-check-field-switch-before-background: color(disabled);
$form-check-field-switch-after-background: color(secondary-active);
$form-check-field-switch-after-box-shadow: none;
// Checked state
$form-check-field-switch-checked-before-background: color(secondary-active);
$form-check-field-switch-checked-after-background: color(disabled);

@import "@lora/05-components/form/check-field";