$tooltip-min-width: rem-calc(150);
$tooltip-arrow-size: rem-calc(10);
$tooltip-close-top: 0;
$tooltip-close-top--large: 0;
$tooltip-close-right: 0;
$tooltip-close-border: none;
$tooltip-close-padding: rem-calc(5 7);
$tooltip-close-background: none;
$tooltip-border-radius: 0;
$tooltip-small-fullwidth-margin: rem-calc(0 -10);
$tooltip-content-padding: rem-calc(16);

@import "@lora/05-components/tooltip";